import React from "react";
import { useTranslation } from "react-i18next";

import { Questions } from '../../Questions'
import { ContentList } from '../../ContentList'

import { Go } from '../../Go'


export const LandingContentAuthenticated = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <h1 className="no-margin-bottom">{t("landing-intro:auth:heading")}</h1>
      <div className="no-margin-bottom" dangerouslySetInnerHTML={{ __html: t("landing-intro:auth:top-content") }} />

      <ContentList 
        listName={"landing-intro"}
        listItemsContent={t("landing-intro:auth:list")}
      />
      <div className="no-margin-bottom" dangerouslySetInnerHTML={{ __html: t("landing-intro:auth:bottom-content") }} />

      <Go />
      <Questions />
    </React.Fragment>
  )
}