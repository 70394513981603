import React, { useState } from "react";
import { useMutate } from "restful-react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { OAuthSignOutButton } from '../buttons'
import { Questions } from '../Questions'

import "../assets/scss/componentCss/App.scss";


/**
 * Helper for rendering <Enroll /> as a standalone item.
 *   Adds 
 *      - Additional UX elements like Questions block, Auth Buttons
 *      - (Optional) Redirect handling
 */
export const EnrollWrapper = () => {
  return (
    <div id="enrollment-wrapper">
      <Enroll />
      <Questions />
      <OAuthSignOutButton />
    </div>
  );
}

/**
 * Component for PIN entry and validation
 *    Does not include additional form component (e.g. Questions, Auth Buttons)
 */
export const Enroll = () => {
    const [pin, setPin] = useState<string>("");
    // debounce calls
    const [valid, setValid] = useState<boolean>(false);
    const [attempted, setAttempted] = useState<boolean>(false);
    const [failureCount, setFailureCount] = useState(0);

    const [streetAddress, setStreetAddress] = useState<string>("");
    const [zip5, setZip5] = useState<string>("");
    const [addressFailureCount, setAddressFailureCount] = useState(0);
    const history = useHistory();

    const { t } = useTranslation();

    // as data changes on the screen...
    const handleChange = (e:any) => {
        setPin(e.target.value);
    }

    // ... call the service
    const { mutate: getCode, loading } = useMutate({
      verb: "POST",
      path: `/validate`,
    });

    const validatePin = () => {
        if (!loading) {
            getCode({ code: pin })
                .then((r) => {
                    setValid(r.valid);
                    setAttempted(true);
                    if (r.valid) {
                        localStorage.setItem("pin", pin);
                        setFailureCount(0);
                        history.push("/surveys");
                    } else {
                        setFailureCount(failureCount + 1)
                        localStorage.removeItem("pin")
                    }
                })
                .catch((e) => console.log(e));
        }
    } ;


    // as data changes on the screen ...
    const handleStreetAddressChange = (e:any) => {
      setStreetAddress(e.target.value);
    }
    const handleZip5Change = (e: any) => {
      setZip5(e.target.value);
    };

    const validateAddress = () => {
        getCode({ address: streetAddress, zip5: zip5 })
          .then((r) => {
            if (r.valid) {
              setPin(r.code);
              localStorage.setItem("pin", r.code);
              setAddressFailureCount(0);
              history.push("/");
            } else {
              setPin("");
              localStorage.removeItem("pin");
              setAddressFailureCount(addressFailureCount + 1);
            }
            setValid(r.valid);
            setAttempted(true);
          })
          .catch((e) => console.log(e));
    } ;

    var pinPlaceholder = {'placeholder': t("pin:placeholder")};
    var addressPlaceholder = {'placeholder': t("address:placeholder")};

    return (
      <div>
        <h1>{ t("enroll:thank-you") }</h1>
        <h3>{ t("enroll:heading") }</h3>
        <p>{ t("pin:intro") }</p>
        <div className="form-item--inline-group">
          <div className="form-item">
            <input
              className="form-item__textfield input--pin"
              type="text"
              name="pin"
              value={pin}
              onChange={(e) => handleChange(e)}
              {...pinPlaceholder}
            />
          </div>
          <div className="form-item">
            <button className="key-btn-verifypin" disabled={pin.length !== 6} onClick={(e) => validatePin()}>
             { t("pin:button") }
            </button>
          </div>
        </div>
        {/* invalid, service was called and pin input */}
        {!valid && attempted && pin.length > 1 ? (
          <div className="well well--form-help"
            dangerouslySetInnerHTML={{ __html: t("pin:errorblock") }}
          />
        ) : (
          ""
        )}
        {/*<div dangerouslySetInnerHTML={{ __html: t("pin:info") }} />*/}


        <h3>{ t("address:heading") }</h3>
        <p>{ t("address:intro") }</p>

        <div className="form-item--inline-group">
          <div className="form-item">
            <input
              className="form-item__textfield input--address"
              id="input--address"
              onChange={(e) => handleStreetAddressChange(e)}
              {...addressPlaceholder}
            />
          </div>
          <div className="form-item">
            <input
              className="form-item__textfield input--zip"
              id="input--zip"
              max-length="5"
              placeholder="ZIP-5"
              onChange={(e) => handleZip5Change(e)}
            />
          </div>
          <div className="form-item">
            <button
              disabled={streetAddress.length < 5 || zip5.length !== 5}
              className="key-btn-verifyaddress"
              onClick={(e) => validateAddress()}
            >
              { t("address:button") }
            </button>
            {addressFailureCount > 3 ? <em>Recapcha here</em> : ""}
          </div>
        </div>
        {/* invalid, service was called and pin input */}
        {!valid && attempted && zip5.length > 1 ? (
          <div className="well well--form-help"
            dangerouslySetInnerHTML={{ __html: t("address:errorblock") }}
          />
        ) : (
          ""
        )}

        {
          /*<h3>{ t("enroll:troubleHeading") }</h3>
          <div dangerouslySetInnerHTML={{ __html: t("enroll:troubleContent") }}></div>*/
        }
      </div>
    );
}
