import React from 'react'
import { useTranslation } from "react-i18next";

// No need for an 'active' state at the moment.
// First is for this stie, second is for ohsu.edu.
// If they're here, first is always 'active.
export default function SiteNavigation() {
    // load the translator hook
  const { t } = useTranslation();

  const faq_url = {'href': t("navigation:faq_url")};
  return (
    <div className="layout-full-width nav-sticky">
      <div className="layout-inner-wrap-flush nav-wrap">

        <nav id="nav-main">
          <input type="checkbox" id="main-nav--trigger" className="toggle-trigger main-nav--trigger" />

          <ul className="nav-main">
            <li className="nav-main__item">
              <a
                {...faq_url}
                className="nav-main__link">
                 {t("navigation:faq")}
              </a>
            </li>
            <li className="nav-main__item nav-main__item--active-trail">
              <a href="/" className="nav-main__link is-active">
              {t("navigation:enroll")}
              </a>
            </li>
          </ul>

        </nav>

      </div>
    </div>
  )
}