/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:52ab0c76-8898-41d5-b53b-6b47518dcad2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_4f2O5zX8P",
    "aws_user_pools_web_client_id": "73k0bb1k7l5n5debgt4s8i9sf9",
    "oauth": {
        "domain": "k2otest76d4390a-76d4390a-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://keystudydev.ohsu.edu/sign-in/,http://localhost:3000/sign-in/,https://staging.d3fz7f7jo85uhm.amplifyapp.com/sign-in/",
        "redirectSignOut": "https://keystudydev.ohsu.edu/,http://localhost:3000/,https://staging.d3fz7f7jo85uhm.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
