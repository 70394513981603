import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { useTranslation } from "react-i18next";

import { AuthProps } from '../t_AuthProps'
import { AuthPaths, NavPaths } from '../../../../constants' 

import { Email, Password } from '../../formFields'
import { HelperLink } from '../HelperLink'


/**
 * handle 'sign-in' or transition to sign-up, confirm-sign-up, forgot-password
 */
export const SignIn = ({ userState, setUserState, setMessage } : AuthProps ) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation('auth');

  const handleSignIn = async () => {
    try {
      //const user = await Auth.signIn(email, password);
      //console.log("custom login ok:", user);
      await Auth.signIn(email, password);
      localStorage.removeItem("signUpEmail");
      window.location.replace("/"+NavPaths.Surveys); //redirect to /surveys
    } catch (error) {
      //console.log(error)
      setMessage(error.message);
    }
  };

  const handleForgotPassword = async () => {
    try {
      localStorage.setItem("signUpEmail", email);
      await Auth.forgotPassword(email);
      setMessage(t("code-sent-check-email"));
      setUserState(AuthPaths.ForgotPassword);      
    } catch (error) {
      //console.log(error);
      setMessage(error.message);
    }
  };

  /*if (! ['unknown', 'sign-in'].includes(userState)) {
    return (null);
  }*/

  if ( AuthPaths.SignIn !== userState && userState !== 'unknown' ) {
    return null;
  }

  const _email: string = localStorage.getItem("signUpEmail") || "";
  if (_email !== '' && email === '') {
    setEmail(_email)
  };

  return (
    <React.Fragment>
      <h5>{t("sign-in-email-header")}</h5>

      <Email setEmailInCaller={setEmail} emailValue={email} />
      <Password setPasswordInCaller={setPassword} />

      <div className="form-row auth-helper-links auth-bottom-content">
        <HelperLink 
          displayText={t("have-confirmation-code")}
          buttonText={t("enter-confirmation-code")}
          buttonId={"confirm-sign-up"}
          onClickCB={(e:any) => setUserState(AuthPaths.ConfirmSignUp)}
        />

        <HelperLink 
          buttonText={t("forgot-password")}
          buttonId={"forgot-password"}
          onClickCB={(e:any) => handleForgotPassword()}
        />

        <HelperLink 
          buttonText={t("create-account")}
          buttonId={"sign-up"}
          onClickCB={(e:any) => setUserState(AuthPaths.CreateAccount)}
        />        
      </div>

      <input
        type="submit"
        onClick={(e) => handleSignIn()}
        id="signin-button"
        className="btn-sign-in"
        //@ts-ignore
        value={t("sign-in")}
      />
    </React.Fragment>
  );
};