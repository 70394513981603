import React from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import { AuthPaths } from '../constants/AuthPaths' 


/**
 * parameters to pass to Component
 */
type OAuthSignInButtonProps = {
  id: string,
  destination: AuthPaths,
  renderAsText?: boolean
};


/**
 * Routes user to sign-in page
 */
 export const OAuthSignInButton = (props: OAuthSignInButtonProps) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const buttonText = "auth:"+props.destination.toString()
    const buttonLinkText = "auth:"+props.destination.toString()+"-link-text" //because sometimes the text in a sentence link needs to be diff from a button

    return (
      React.createElement(
        props.renderAsText === true ? 'a' : 'button', // type of element to render
        { // props to pass to the rendered element
          id: props.id,
          className: 'key-btn-signin btn-as-text',
          onClick: () => {
            history.push("/"+props.destination)}
        },
        i18n.exists(buttonLinkText) //what text to display
          ? t(buttonLinkText)
          : t(buttonText)
      )
    );
}