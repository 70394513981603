import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { useTranslation } from "react-i18next";

import { AuthProps } from '../t_AuthProps'
import { AuthPaths } from '../../../../constants/AuthPaths' 

import { Email, Password } from '../../formFields'
import { HelperLink } from '../HelperLink'


/**
 * handle 'sign-up' or transition to sign-in
 */
export const CreateAccount = ({ userState, setUserState, setMessage }: AuthProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation('auth');


  const handleSignUp = async () => {
    try {
      await Auth.signUp(email, password);
      localStorage.setItem("signUpEmail", email);
      setMessage(`${t('confirm-check-email')}, ${email}`)
      setUserState(AuthPaths.ConfirmSignUp);
    } catch (error) {
      console.log(error);
      setMessage(error.message);
    }
  };

  if ( AuthPaths.CreateAccount !== userState) {
    return null;
  }

  return (
    <React.Fragment>
      <h5>{t("create-account-email-header")}</h5>
      <Email setEmailInCaller={setEmail} />
      <Password setPasswordInCaller={setPassword} />

      <div className="form-row auth-helper-links auth-bottom-content">
        {/*<HelperLink 
          displayText={t("have-account-question")}
          buttonText={t("sign-in")}
          buttonId={"sign-in"}
          onClickCB={(e:any) => setUserState(AuthPaths.SignIn)}
        />*/}

        <HelperLink 
          displayText={t("have-confirmation-code")}
          buttonText={t("enter-confirmation-code")}
          buttonId={"confirm-sign-up"}
          onClickCB={(e:any) => setUserState(AuthPaths.ConfirmSignUp)}
        />
      </div>

      <input
        type="submit"
        id="signup-button"
        onClick={(e) => handleSignUp()}
        className="btn-sign-in"
        //@ts-ignore
        value={t("create-account")}
      />
    </React.Fragment>
  );
};