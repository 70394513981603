import React from "react";
import { useTranslation } from "react-i18next";

import { Questions } from '../../Questions';
import { LearnMore } from '../../LearnMore';
import { ContentList } from '../../ContentList';

import { Go } from '../../Go';
import { SignInBlock } from '../../Authentication/SignIn/SignInBlock';


export const LandingContentAnon = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <h1 className="no-margin-bottom">{t("landing-intro:anon:heading")}</h1>
      <p className="no-margin-bottom">{t("landing-intro:anon:welcome")}</p>

      <ContentList 
        listName={"landing-intro"}
        listItemsContent={t("landing-intro:anon:list")}
        additionalItemsMarkup={
          <li key="landing--list-content-item-questions" className="landing--list-content-item questions">
            <Questions renderInline={true} />
          </li>
        }
      />

      <Go />

      <div className="row no-margin-sides no-padding-sides landing-gray-side-by-side">
        <div className="column">
          <SignInBlock />
        </div>

        <div className="column column--2">
          <div className="oui-block oui-block-padding">
            <LearnMore />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}