import React from 'react'
import "./assets/scss/componentCss/SiteHeader.scss";
import { ReactComponent as Logo } from './assets/img/logo--ohsu__main.svg';
import SiteNavigation from './SiteNavigation';
import LanguageSwitcher from './Lang/LanguageSwitcher';
import { useTranslation } from "react-i18next";
import { UserContext } from "./Contexts/UserContext";

export const SiteHeader = () => {
  // load the translator hook
  const { t } = useTranslation();
  const appContextState:any = React.useContext(UserContext);

  return (
    <header id="header" className="header site-header" role="banner" aria-label="Site header">
      <div className="layout-full-width header-title-wrap">
        <div className="title-bar-wrap">
          <div className="title-bar-inner-wrap">
            <div className="nav-home">
              <a href="http://www.ohsu.edu" rel="home" className="nav-logo">
                <Logo />
              </a>
              <h1 className="header-title no-parent-url">
                <span className="header-title__parent">
                </span>
                <em className="header-title__name">
                  <a className="header-title__link" href="/">
                    {t("general:site-title")}
                  </a>
                </em>
              </h1>
            </div>
            <div className="nav-utility">
              <div className="locale-switcher" id="locale-switcher">
                <LanguageSwitcher />
              </div>
              { appContextState.user !== null
                ? (<div className="header--current-user">
                    Signed in as <u>{appContextState.user.attributes.email}</u>
                  </div>)
                : ''
              }
            </div>
            <div className="nav-options">
              <label htmlFor="main-nav--trigger"><span>Menu</span></label>
            </div>
          </div>
        </div>
      </div>
      <SiteNavigation />
    </header>
  )
}

export default SiteHeader;