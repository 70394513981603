import React from "react";
import { useTranslation } from "react-i18next";

import PartnerImage from './assets/img/logos--partners.png'
import PartnerImage2x from './assets/img/logos--partners@2x.png'


export const Partners = () => {
  // load the translator hook
  const { t } = useTranslation();

  return (
    <div className="block--partners divider--dashed">
      <h3 className="size-h2">{t("general:partners-heading")}</h3>
      <img
        src={PartnerImage}
        srcSet={`${PartnerImage}, ${PartnerImage2x} 2x`}
        alt="Partner logos: OHSU, PSU and OHA"
      />
    </div>
  )
}