import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * parameters to pass to Code
 */
type ConfirmationCodeProps = {
  setCodeInCaller : (code: string) => void ;    // tell caller of state change
} ;


/**
 * Render code
 */
export const ConfirmationCode = ({ setCodeInCaller }: ConfirmationCodeProps) => {
  const [code, setCode] = useState("");
  const { t } = useTranslation("auth");
  
  const formElementName = 'code-input'
  return (
    <label htmlFor={formElementName} className="form-row wide">
      <span className='label-text'>{t("code_label")}:</span>
      <input
        type="code"
        value={code}
        id={formElementName}
        name={formElementName}
        placeholder={t("code_placeholder")}
        onChange={(e) => {setCode(e.target.value); setCodeInCaller(e.target.value);}}
      />
    </label>
  );
};