import React from "react";
import { Auth } from "aws-amplify";
import { useMutate } from "restful-react";
import { Redirect, useHistory, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

import queryString from "query-string";

import { Questions } from './Questions'

import { getToken, getEmail, getPin, getLanguage } from './Authentication/Functions/'
import { ConsentStates } from './constants/ConsentStates'
import { OAuthSignOutButton } from './buttons'
import { UserContext } from "./Contexts/UserContext";
import { LoadingMessage } from './LoadingMessage';
import { AuthPaths, NavPaths } from './constants'

import "./assets/scss/componentCss/Surveys.scss";


/**
 * Render Qualtrics url returned from Cognito
 */
export const Surveys = (props: any) => {
  const appContextState: any = React.useContext(UserContext);
  const user: any = appContextState.user;
  //const loading:boolean = appContextState.loading;

  const [surveysLoading, setSurveysLoading] = React.useState(true);

  const [surveys, setSurveys] = React.useState<any>(null);

  // survey items
  const surveyUrl = process.env.REACT_APP_SURVEY_URL;
  const demographicsSurveyID = process.env.REACT_APP_DEMO_SURVEY_ID;
  const costOfCovidSurveyID = process.env.REACT_APP_COVIDCOST_SURVEY_ID;
  const kinsaSurveyID = process.env.REACT_APP_KINSA_SURVEY_ID;
  const informedSurveyID = process.env.REACT_APP_INFORMED;
  const experienceSurveyID = process.env.REACT_APP_EXPERIENCE;
  const healthSurveyID = process.env.REACT_APP_HEALTH;
  const foodAccessSurveyID = process.env.REACT_APP_FOOD_ACCESS;
  const spreadPreventionSurveyID = process.env.REACT_APP_SPREAD_PREVENTION;
  const realdSurveyID = process.env.REACT_APP_REALD;

  const [timerId, setTimerId] = React.useState<any | null>(null);
  const [pollingTrigger, setPollingTrigger] = React.useState<string | null>(null);
  const location = useLocation();
  const history = useHistory();

  // //@ts-ignore must be set
  // special cases for consent and daily surveys id usage within component
  //@ts-ignore
  const CONSENT_SURVEY_ID: string = process.env.REACT_APP_CONSENT_SURVEY_ID;
  //@ts-ignore
  const DAILY_SURVEY_ID: string = process.env.REACT_APP_DAILY_SURVEY_ID;

  const { t } = useTranslation();

  let redirectToDaily: boolean = sessionStorage.targetDestination === "/"+AuthPaths.KeyKitSignin ? true : false

  // provides additional survey info, links to localization entries
  let surveyMatrix = [
    { name: "surveys:demographics", id: demographicsSurveyID, status: "todo" },
    { name: "surveys:cost-of-covid", id: costOfCovidSurveyID, status: "todo" },
    { name: "surveys:kinsa", id: kinsaSurveyID, status: "todo" },
    { name: "surveys:daily", id: DAILY_SURVEY_ID, status: "todo" },
    { name: "surveys:informed", id: informedSurveyID, status: "todo" },
    { name: "surveys:experience", id: experienceSurveyID, status: "todo" },
    { name: "surveys:health", id: healthSurveyID, status: "todo" },
    { name: "surveys:foodAccess", id: foodAccessSurveyID, status: "todo" },
    { name: "surveys:spreadPrevention", id: spreadPreventionSurveyID, status: "todo" },
    { name: "surveys:reald-demographics", id: realdSurveyID, status: "todo" },
  ];
  const { mutate: getUserSurveys } = useMutate({
    verb: "POST",
    path: `/user-surveys`,
  });

  // consistent way to collect from cognito
  //const getToken = (_user: any) =>
  //  _user?.signInUserSession.idToken.payload.qualtrics_token;

  // consistent way to collect from cognito
  //const getEmail = (_user: any) => _user?.attributes?.email;

  // fetch the info of the user that may be already logged in
  // TODO: should pull from context; revise this for 7.0
  React.useEffect(() => {
    let isMounted = true;

    Auth.currentAuthenticatedUser()
      .then((_user) => {
        if (isMounted) {
          const qualtrics_token = getToken(_user);
          let _timerId = timerId;

          getUserSurveys({ user_id: qualtrics_token }).then((_surveys) => {
            const surveysChanged = _surveys.length !== surveys?.length
            if (surveysChanged && _timerId) {
              clearInterval(_timerId);
              setTimerId('done');
              //console.log(`surveysChanged stopped polling ${new Date().getTime()}`);
            } else if (_timerId) {
              //console.log(`surveys didn't change still polling ${new Date().getTime()}`);
            }

            /**
             * Are we activated by a qualtrics callback? Is a timer currently running?
             */
            const survey_complete = queryString.parse(location.search)?.survey_complete !== undefined;
            if (survey_complete && !_timerId) {
              // repeat with the interval of 2 seconds
              _timerId = setInterval(() => { setSurveysLoading(true); setPollingTrigger(`polling/${new Date().getTime()}`) }, 2000);
              setTimerId(_timerId);
              //console.log(`started polling ${new Date().getTime()}`)
              // after 10 seconds stop
              setTimeout(() => {
                if (!_timerId) {
                  //console.log(`timer already cleared ${new Date().getTime()}`);
                } else {
                  clearInterval(_timerId);
                  setTimerId('done');
                  //console.log(`stopped polling ${new Date().getTime()}`);
                  // Clear the query string. So, if user refreshes page, it behaves normally
                  history.replace("/"+NavPaths.Surveys);
                }
              }, 24000);
            }

            setSurveys(_surveys);
            setSurveysLoading(false);
          });
        }
      })
      .catch((e) => { console.log(e); setSurveysLoading(false) });

    return () => {
      isMounted = false;
    }
    // eslint-disable-next-line
  }, [pollingTrigger]);
  // above line gives a "Either include it or remove the dependency array"
  // removing it causes a re-render loop?

  /**
   * parameters to survey
   */
  const pin = getPin()
  const qualtrics_token = getToken(user);
  const email = getEmail(user);
  const language = getLanguage()

  /**
   * config error
  */
  if (!surveyUrl) {
    console.error("Please set SURVEY_URL");
    return (
      <div>
        <h1>{t("general:surveys-heading")}</h1>
        <h2>"Deployment error: Please set SURVEY_URL in environment"</h2>
      </div>
    );
  }

  // Utility method to generate a full qualtrics url.
  // destination may be a url
  const getSurveyURL = (
    destination: string,
    qualtricsToken: string,
    email: string,
    language: string,
    pin: string,
    surveyId: string
  ) => {
    if (destination.startsWith('http')) {
      return destination
    }

    // Qualtrics needs the language shortcode to be uppercase.
    let q_language = language.toUpperCase();

    let url = `${surveyUrl}?SID=${surveyId}&ssotoken=${qualtricsToken}&Q_Language=${q_language}&pin=${pin}&email=${email}`;

    return url;
  };

  // user not authenticated - handled by React Router

  /**
   * MAIN content
   */
  if (surveysLoading || (timerId && timerId !== "done")) {
    return <LoadingMessage />;
  }

  const renderSurveyResults = () => {
    if (!surveys) {
      return <Redirect to={NavPaths.Landing} />;
    }

    /**
     * CONSENT CHECK - redirect back to /go for unconsented user
     */
    const consentSurvey = surveys.filter((o: { survey_id: string; }) => o.survey_id === CONSENT_SURVEY_ID);

    /**
     * add additional info to surveys
    */
    for (let s of surveys) {
      let mMatch = surveyMatrix.find(e => (e.id === s.survey_id));
      if (mMatch) { 
        mMatch.status = "complete"
        console.log("survey " + s.survey_id +" check... " + mMatch.status);
      }
    }

    /**
     * identify daily surveys and process
     */
    let dailySurveys = surveys.filter((o: { survey_id: string; }) => o.survey_id === DAILY_SURVEY_ID);
    if (dailySurveys.length > 0) {
      // get date of most recent completed daily
      let latestSurveyComplete = new Date(Math.max.apply(null, dailySurveys.map(function (e: { timestamp: string; }) {
        return new Date(e.timestamp);
      })));
      // strip time values
      let dailyComplete = latestSurveyComplete.setHours(0, 0, 0, 0);
      let today = new Date().setHours(0, 0, 0, 0);
      if (dailyComplete < today) {
        // user needs to complete daily survey
        let mDaily = surveyMatrix.find(e => (e.id === DAILY_SURVEY_ID));
        if (mDaily) { mDaily.status = "todo" };
      }
    }

    // If consent survey wasn't found, prompt user for consent
    if (consentSurvey.length === 0) {
      if (getPin()) {
        let consentSurveyUrl = getSurveyURL(NavPaths.Surveys, getToken(user), getEmail(user), language, pin, CONSENT_SURVEY_ID);
        return (
          <Route path={'/'+NavPaths.Surveys} component={() => {
            window.location.replace(consentSurveyUrl);
            return null;
          }} />
        )
      }
      else {
        return <Redirect to={NavPaths.Landing} />;
      }
    }
    // Consent survey found and user consented
    else if (consentSurvey.length === 1 && consentSurvey[0].consented === ConsentStates.Consented) {
      //console.log("consent verified")
      if (getPin()) {
        //if the pin is still in local storage after consent is detected, remove it to clear state
        localStorage.removeItem("pin");
      }
      sessionStorage.removeItem('targetDestination');
      if (redirectToDaily) { //nested if isn't great, but we might add more branched stuff here. leaving it for now in favor of a more agressive refactor anyway
        window.location.replace(getSurveyURL(NavPaths.Surveys, getToken(user), getEmail(user), language, pin, DAILY_SURVEY_ID));
      }
    }
    else { //Consent survey found and no consent
      window.location.replace("https://www.ohsu.edu/key-to-oregon");
      return;
    }


    /**
     *  group surveys for display
     */
    let todoSurveys = surveyMatrix.filter(o => o.status === "todo");
    let demoSurvey = todoSurveys.filter(o => o.name === "surveys:demographics");
    let realdSurvey = todoSurveys.filter(o => o.name === "surveys:reald-demographics");
    console.log(realdSurvey.length === 1 ? "reald found in TODO":"");
    // let activeLinks = (demoSurvey.length === 1 ? demoSurvey : todoSurveys);
    let activeLinks = (realdSurvey.length === 1 ? realdSurvey : todoSurveys);
    console.log(activeLinks);

    return (
      <div style={{ marginTop: 20 }}>
        {activeLinks && activeLinks.length > 0 && <div><h2>{t("surveys:instructions1")}</h2><h4 className="hdr-surveys-available">{t("surveys:instructions2")}</h4></div>}
        {activeLinks && activeLinks.length === 0 && <div><h2>{t("surveys:complete1")}<br /></h2><p dangerouslySetInnerHTML={{ __html: t("surveys:complete2") }} /> </div>}        
        <hr />
        {demoSurvey.length === 1 && realdSurvey.length === 1 && <div><p>{t("surveys:newParticipantsDemographics")}</p></div>}
        {demoSurvey.length === 0 && realdSurvey.length === 1 && <div><p>{t("surveys:returningParticipantsDemographics")}</p></div>}
        <ul className="section-surveys-available">
          {activeLinks?.map((s: any, idx: number) => (
            <li className={"available-survey survey-" + (idx + 1)} key={s.id}>
              <a href={`${surveyUrl}?SID=${s.id}&ssotoken=${qualtrics_token}&language=${language}&pin=${pin}&email=${email}`}>
                {t(s.name)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  ReactGA.pageview("/"+NavPaths.Surveys);

  return (
    <div className="page--surveys">
      {/* <h1>{ t("surveys:heading") }</h1> */}
      <div>
        {renderSurveyResults()}
      </div>
      <Questions />
      <OAuthSignOutButton />
    </div>

  );
};
