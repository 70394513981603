import React from "react";
import { useTranslation } from "react-i18next";

export const LanguageSwitcher = () => {
  // load the translator hook
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState<string>(i18n.language)

  const codes = t("language:codes")?.split(",");
  const labels = t("language:texts")?.split(",");

  const handleChange = (e:any) => {
    // set it in translator when it changes
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("language", e.target.value);
    setLanguage(e.target.value);
  } ;

  return (
      <div id="lang-switcher">
        <label htmlFor="language" className="locale-switcher__label">
          {t("language:languageUpper")}
        </label>
        <select
          onChange={(e) => handleChange(e)}
          className="locale-switcher__select"
          id="language"
          value={language}
        >
          {codes.map((c: string, i: number) => (
            <option key={c} value={c}>
              {labels[i]}
            </option>
          ))}
        </select>
      </div>

  )
};

export default LanguageSwitcher;