import React from "react";
import { useHistory } from "react-router-dom";
import { Hub } from "aws-amplify";
import { K2OAuthenticator } from "../K2OAuthenticator";

/**
 * parameters to pass to Code
 */
type SignInPageProps = {
  destination: string
} ;

/**
 * Handle cognito returns
 * TODO Refactor to fix backbutton loop
 */
export const SignInPage = (props: SignInPageProps) => {
  const history = useHistory();
  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        if ( localStorage.getItem("invitation") ) {
          history.push("/newaccount");
        } else {
          history.push("/");
        }
        break;
      case "signOut":
        // console.log("now the user is signed out");
        break;
      case "signIn_failure":
        //console.log("the user failed to sign in");
        //console.log("error signing in:", data.payload.data);
        break;
      default:
        // console.log("unhandled event", data.payload.event);
        break;
    }
  });

  /**
   * main page
  */
 return (
  <div>
    <K2OAuthenticator destination={props.destination} />
  </div>
 );
};
