import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { useTranslation } from "react-i18next";

import { AuthProps } from '../t_AuthProps'
import { AuthPaths } from '../../../../constants/AuthPaths' 

import { ConfirmationCode, Email } from '../../formFields'
import { HelperLink } from '../HelperLink'


/**
 * handle 'confirm-sign-up', 'resend-confirmation-code' or transition to sign-in
 */
export const ConfirmSignUp = ({ userState, setUserState, setMessage }: AuthProps) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const { t } = useTranslation('auth');

  const handleConfirmSignUp = async () => {
    try {
      await Auth.confirmSignUp(email, code);
      setUserState(AuthPaths.SignIn);
    } catch (error) {
      console.log(error);
      setMessage(error.message);
    }
    localStorage.removeItem("signUpEmail");
  };

  const handleResendConfirmationCode = async () => {
    try {
      await Auth.resendSignUp(email);
      setMessage(t("code-sent-check-email"));
    } catch (error) {
      console.log(error);
      setMessage(error.message);
    }
  };

  if (!["confirm-sign-up", "resend-confirmation-code"].includes(userState)) {
    return null;
  }
  const _email: string = localStorage.getItem("signUpEmail") || "";
  if (_email !== '' && email === '') {
    setEmail(_email)
  };
  return (
    <React.Fragment>
      <h5>{t("confirm-sign-up")}</h5>
      <Email setEmailInCaller={setEmail} wide={true} emailValue={email} />
      <ConfirmationCode setCodeInCaller={setCode} />

      <div className="form-row auth-helper-links auth-bottom-content">
        <HelperLink 
          displayText={t("lost-code-question")}
          buttonText={t("resend-code")}
          buttonId={"resend-code"}
          onClickCB={(e:any) => handleResendConfirmationCode()}
        />

        {/*
          <HelperLink 
            buttonText={t("back-to-sign-in")}
            buttonId={"back-to-sign-in"}
            onClickCB={(e:any) => setUserState(AuthPaths.SignIn)}
          />
        */}
      </div>

      <input
        type="submit"
        onClick={(e) => handleConfirmSignUp()}
        id="confirm-button"
        className="btn-sign-in"
        //@ts-ignore
        value={t("Confirm")}
      />
    </React.Fragment>
  );
};
