import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: require("../content/local.en.json"),
  es: require("../content/local.es.json"),
};

const supported_language_codes = ["en", "es"];

// Set our default language.
let app_language : string = "en";

// If the user explicitly chose a language in our app, use it:
if (localStorage.getItem("language")) {
  app_language = localStorage.getItem("language") || app_language;
}
// Otherwise, check what the browser is reporting.
else if (window.navigator.language) {
  app_language = window.navigator.language.slice(0, 2);
}

// Just in case...
// If app_language is NOT in our array of supported languages, set it to "en".
// I think "i18n.fallbackLng" covers this but, yeah, just in case.
app_language = supported_language_codes.includes(app_language) ? app_language : "en";

const nameSpaces = [
  "address",
  "enroll",
  "general",
  "landing-intro",
  "language",
  "navigation",
  "pin",
  "verified",
];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: nameSpaces,
    lng: app_language,
    fallbackLng: "en",
    keySeparator: ".", // we do use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // special options for react-i18next
    // learn more: https://react.i18next.com/latest/i18next-instance
    react: {
      wait: true,
    }
  });

export default i18n;
