import React from "react";
import { Auth } from 'aws-amplify';
import { useTranslation } from "react-i18next";

import { GoogleIcon, FacebookIcon, SquareIcon } from '../../../graphics'

/**
 * Helper component for External Authentication Buttons
 *
 *  Required Props:
 *    provider: string
 */
export const SocialButton = (props: any) => {
  const { t } = useTranslation("auth");
  const authIconComponentMap:any = {
    'google': GoogleIcon,
    'facebook': FacebookIcon,
    'default': SquareIcon
  }
  if (!(props.provider.toLowerCase() in authIconComponentMap)) 
    console.error("Error: missing ext auth provder icon: ", props.provider.toLowerCase())

  const ExtAuthIcon = authIconComponentMap[props.provider.toLowerCase() || 'default']

  return (
    <button
      id={"btn-ext-auth-"+props.provider.toLowerCase()}
      className="btn-ext-auth"
      onClick={() =>
        //@ts-ignore
        Auth.federatedSignIn({ provider: props.provider })
      }
    >
      <span className="icon">
        <ExtAuthIcon />
      </span>
      <span className="btn-text">{t(props.provider)}</span>
    </button>
  )
}