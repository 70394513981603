import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * parameters to pass to Password
 */
type PasswordProps = {
  setPasswordInCaller : (password: string) => void ;    // tell caller of state change
  wide?: boolean
} ;


/**
 * Render password
 */
export const Password = ({ setPasswordInCaller, wide }: PasswordProps) => {
  const [password, setPassword] = useState("");
  const { t } = useTranslation("auth");

  const formElementName = 'password-input'
  return (
    <label htmlFor={formElementName} className={"form-row"+(wide === true ? ' wide' : '')}>
      <span className='label-text'>{t("password_label")}:</span>
      <input
        type="password"
        value={password}
        id={formElementName}
        name={formElementName}
        placeholder={t("password_placeholder")}
        onChange={(e) => {
          setPassword(e.target.value);
          setPasswordInCaller(e.target.value);
        }}
      />
    </label>
  );
};