import React from "react";
import queryString from "query-string";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { useMutate } from "restful-react";
import { UserContext } from "../../Contexts/UserContext";
// import { useTranslation } from "react-i18next";



/**
 * read invite from query string parameter("i") or localStorage.
 */
export const getInvitation = (location:any) :string|null => {
  // @ts-ignore
  let invitation: string | null  = queryString.parse(location.search)?.i;
  if (invitation) {
    // Disable localstorage for now anyway. Want to test with bad invites.
    localStorage.setItem("invitation", invitation);
    return invitation;
  } else {
    invitation = localStorage.getItem("invitation");
  }
  return invitation
}

type InvitationProps = {
  qualtrics_token: string,
  setInvitationState: (state: string) => void
}
export const Invitation = ({qualtrics_token, setInvitationState} : InvitationProps) => {
  // TODO: fix these warnings @typescript-eslint/no-unused-vars
  // eslint-disable-next-line
  const [message, setMessage] = React.useState<any>(null);
  // eslint-disable-next-line
  // const [user, setUser] = React.useState<any>(null);
  const appContextState:any = React.useContext(UserContext);
  // const user = React.useContext(UserContext);
  // eslint-disable-next-line
  const [authState, setAuthState] = React.useState<string>("LOADING");
  // const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  // const { t } = useTranslation();


  // ... call the service
  const { mutate: redeem } = useMutate({
    verb: "POST",
    path: `/invitations/redeem`,
  });
 // fetch the info of the user that should be already logged in
  React.useEffect(() => {
    let isMounted = true;
    const invitation_id = getInvitation(location);

    if (qualtrics_token && invitation_id) {
      redeem({ user_id: qualtrics_token, invitation_id: invitation_id })
        .then((_message) => {
          if(isMounted){
            setMessage(_message.message);
            //tell parent
            setInvitationState(_message.message);
            // setLoading(false);
            localStorage.removeItem("invitation");
          }
        })
        .catch((e) => {
          console.log("catch e", e);
          // setLoading(false);
        });
    } else {
      setMessage({message: "No invitation."})
    }

    return () => {
      isMounted = false;
    }

  // eslint-disable-next-line
  }, []);
  // above line gives a "Either include it or remove the dependency array"
  // removing it causes a re-render loop?

  // Detects that the user is already logged in
  React.useEffect(() => {
    let isMounted = true;

    Auth.currentAuthenticatedUser()
      .then((_user) => {
        if (isMounted) {
          // we have a user who was logged in previously
          // setUser(_user);
          setAuthState("LOGGED_IN");
        }

      })
      .catch((e) => {
        setAuthState("LOGGED_OUT");
      });

    return () => {
      isMounted = false;
    }

    }, [location]);

  // render widget
  if (appContextState.loading) {
    return(
      <div>{appContextState.loading}</div>
    )
  }

  return null;

}
