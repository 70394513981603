import React from "react";


/**
 * parameters to pass to Component
 */
type ContentListProps = {
  listName: string,
  listItemsContent: string,
  additionalItemsMarkup?: any,
  additionalListClasses?: string
};

export const ContentList = (props:ContentListProps) => {
  return (
    <ul id={"list-"+props.listName} className="content--list-content">
      {
        props.listItemsContent.split('||').map((listItem, idx) => {
            return (<li key={"content--list-"+props.listName+"-item-"+idx} className="content--list--item" dangerouslySetInnerHTML={{ __html: listItem }}></li>);
          }
        )
      }
      {props.additionalItemsMarkup}
    </ul>
  )
}