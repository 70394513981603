// These must be the first lines in src/index.js
import "react-app-polyfill/ie11";
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/scss/componentCss/index.scss";
import App from './App';
import * as serviceWorker from './serviceWorker';

// TagManager causing: Could not find a declaration file for module 'react-gtm-module'.
// @ts-ignore
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import "./Lang/i18n";

import { BrowserRouter } from "react-router-dom";


const gaTrackingID = "UA-15717535-13";
ReactGA.initialize(gaTrackingID);

const tagManagerArgs = {
  gtmId: 'GTM-TTQ42RD'
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
