import React from "react";
import { useTranslation } from "react-i18next";

import { EmailTargets } from './constants'
import { UserContext } from "./Contexts/UserContext";

import "./assets/scss/componentCss/Landing.scss";


/**
 * parameters to pass to Code
 */
type QuestionsProps = {
  renderInline?: boolean;
} ;

export const Questions = (props: QuestionsProps) => {
  const appContextState:any = React.useContext(UserContext);
  const { t } = useTranslation();

  let email:string = EmailTargets.QuestionsAnon;
  if (appContextState.user !== null) {
    email = EmailTargets.QuestionsAuthed;
  }

	return (
    <React.Fragment>
      { props.renderInline === true 
        ? 
          <span>
            <span className="strong">{ t("general:questions-heading")+ " " }</span> 
            <span dangerouslySetInnerHTML={{ __html: t("general:questions-content1")+" "+email+"." }} />
            <span dangerouslySetInnerHTML={{ __html: t("general:questions-content2") }} />
          </span>
    		: 
          <div className="block--questions">
      		  <h2 className="block--header">{ t("general:questions-heading") }</h2>
        		<p dangerouslySetInnerHTML={{ __html: t("general:questions-content1")+" "+email+"." }} />
        		<p dangerouslySetInnerHTML={{ __html: t("general:questions-content2") }} />
        	</div>
      }
      </React.Fragment>
	)
}
