import React from "react";
import { Route, Switch, Redirect, RouteProps} from "react-router-dom";

// Import Possible Landing Components
import { Landing } from "./Landing/Landing";
import { SignInPage } from "./Authentication/SignIn/SigninPage";
import { Surveys } from "./Surveys";
import { InvitePage } from './Landing/Invitation/InvitePage'
import { KeyKitLandingPage } from "./Landing/KeyKitLanding";
import { EnrollWrapper } from "./Authentication/Enroll";

import { AuthPaths, NavPaths } from './constants'

import { UserContext } from "./Contexts/UserContext";

// const SurveyAuth = withOAuth(Surveys);
const surveySelect = (targetDestination: string) => {
  sessionStorage.setItem("targetDestination", targetDestination);
  return <Surveys />
}

/**
 *  <Route> extension component to require authentication for certain urls/routes.
 *    Will redirect
 */ 
interface PrivateRouteProps extends RouteProps {
    redirectPath?: string, //optional: where to redirect if not auth'd to access target
}
const PrivateRoute = (props: PrivateRouteProps) => {
  const appContextState:any = React.useContext(UserContext);

  if ((!props.component && !props.render) || appContextState.loading) {
    return null;
  }
  
  if (appContextState.user === null) { //example
    return <Redirect to={props.redirectPath || "/"} />;
  }
  return <Route {...props} />;
}


export const Routing = () => {
	return (
    <Switch>
      <Route path="/" component={Landing} exact />

      <Route path={"/"+AuthPaths.SignIn} render={() => <SignInPage destination={AuthPaths.SignIn} />} />
      <Route path={"/"+AuthPaths.CreateAccount} render={() => <SignInPage destination={AuthPaths.CreateAccount} />} />
      <Route path={"/"+AuthPaths.ConfirmSignUp} render={() => <SignInPage destination={AuthPaths.ConfirmSignUp} />} />
      <Route path={"/"+AuthPaths.ForgotPassword} render={() => <SignInPage destination={AuthPaths.ForgotPassword} />} />      
      <PrivateRoute path={"/"+AuthPaths.Enroll} component={EnrollWrapper} exact redirectPath={"/"+AuthPaths.SignIn} />
      <PrivateRoute path={"/"+NavPaths.Surveys} component={Surveys} exact redirectPath={NavPaths.Landing} />
      <Route path={"/"+NavPaths.KeyKit} component={KeyKitLandingPage} />
      <Route path={"/"+AuthPaths.KeyKitSignin} component={() => surveySelect("/"+AuthPaths.KeyKitSignin)} />
      <Route path={"/"+AuthPaths.NewaccountInvite} component={InvitePage} />
      <Redirect to="/" />
    </Switch>
	)
}
