import React from "react";
import { Auth } from "aws-amplify";
import { RestfulProvider } from "restful-react";
import { withTranslation } from "react-i18next";
import { Amplify, Hub } from "aws-amplify";
//import { useHistory } from "react-router-dom"
import aws_exports from "./aws-exports-fix";

import { SiteHeader } from "./SiteHeader";
import { Routing } from './Routing';

import { UserContext } from "./Contexts/UserContext";

import "./assets/scss/componentCss/App.scss";

Amplify.configure(aws_exports);


const App = () => {
  const servicesURL = process.env.REACT_APP_SERVICES_URL;

  const [user, setUser] = React.useState<any>(null); //current Auth'd user || null
  const [loading, setLoading] = React.useState(true); //App "isLoading" state 
  //const history = useHistory();

  Hub.listen("auth", (data) => {
    //console.log("Landing Hub", data)
    switch (data.payload.event) {
      case "signIn":
        //console.log("now the user is signed in");
        window.location.replace("/");
        setLoading(true); //required to avoid flickering the anon content
        break;
      case "signOut":
        //console.log("now the user is signed out");
        break;
      case "signIn_failure":
        //console.log("the user failed to sign in");
        //console.log("the error is", data.payload.data);
        break;
      default:
        // console.log("unhandled event", data.payload.event);
        console.groupCollapsed(data.payload.event, data);
        break;
    }
  });

  React.useEffect(() => {
    let isMounted = true;

    Auth.currentAuthenticatedUser()
      .then((_user) => {
        if (isMounted) {
          setUser(_user);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e !== 'not authenticated')
          console.log(e);
        setLoading(false);
      });

    return () => {
      isMounted = false;
    }
  // eslint-disable-next-line
  }, []);


  if (!servicesURL) {
    console.error("Please set REACT_APP_SERVICES_URL in environment");
    return (
      <div>
        <h1>{"Whoops"}</h1>
        <h2>
          "Deployment error: Please set REACT_APP_SERVICES_URL in environment"
        </h2>
      </div>
    );
  }

  const contextState:any = {
    user,
    loading
  }

  return (
    <UserContext.Provider value={contextState}>
      <div className="site-wrap">
        <SiteHeader />
        <div className="layout-full-width main-content">
          <div className="layout-inner-wrap-flush">
            <article className="layout-primary">
              <RestfulProvider base={servicesURL}>
                <Routing />
              </RestfulProvider>
            </article>
          </div>
        </div>
      </div>
    </UserContext.Provider>
  );
}

// @ts-ignore
export default withTranslation()(App);
