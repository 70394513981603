import React from "react";
import { useTranslation } from "react-i18next";

import { AuthPaths } from '../../constants/AuthPaths' 
import { OAuthSignInButton } from '../../buttons'

export const SignInBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="block--existing-sign-in block--sign-in oui-block">
      <span className="block--header" dangerouslySetInnerHTML={{ __html: t("landing-intro:anon:login") + " " }} />
      <OAuthSignInButton id="signin--sign-in" destination={AuthPaths.SignIn} renderAsText={true} />
    </div>
  )
}