import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * parameters to pass to EMail
 */
type EmailProps = {
  setEmailInCaller : (email: string) => void ;    // tell caller of state change
  wide?: boolean
  emailValue?:string
} ;


/**
 * Render email
 */
export const Email = ({ setEmailInCaller, wide, emailValue }: EmailProps) => {
  const [email, setEmail] = useState(emailValue || "");
  const { t } = useTranslation('auth'); 

  const formElementName = 'email-input'
  return (
    <label htmlFor={formElementName} className={"form-row"+(wide === true ? ' wide' : '')}>
      <span className='label-text'>{t("email_label")}:</span>
      <input
        type="email"
        value={email}
        id={formElementName}
        name={formElementName}
        placeholder={t("email_placeholder")}
        onChange={(e) => {setEmail(e.target.value); setEmailInCaller(e.target.value)}}
      />
    </label>
  );
};