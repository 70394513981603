import React from "react";
import aws_exports from "../../aws-exports-fix";
import Amplify, { Auth, Hub } from "aws-amplify";
import ReactGA from 'react-ga';

import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom';
import { getInvitation, Invitation } from './Invitation';
import { OAuthSignInButton } from '../../buttons'
import { AuthPaths } from '../../constants/AuthPaths'
import { LoadingMessage } from '../../LoadingMessage';
import { UserContext } from '../../Contexts/UserContext'

Amplify.configure(aws_exports);


export const InvitePage = () => {
  const location = useLocation();
  const history = useHistory();
  const appContextState:any = React.useContext(UserContext);
  // const user: = React.useContext(UserContext);
  // const [user, setUser] = React.useState<any>(null);
  const [authState, setAuthState] = React.useState<string>("LOADING");
  const [invitationState, setInvitationState] = React.useState<string|null>("WAITING");
  const [invitation] = React.useState(getInvitation(location));
  // const history = useHistory()
  const { t } = useTranslation();

  /**
   * Blocks for all user tokens. Async function that will block until all the user auth info is ready.
   */
  const waitForCognito = async () => {
    await Auth.currentAuthenticatedUser();
    setAuthState("LOGGED_IN")
  };

  // Listens for return from cognito.
  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        waitForCognito();
        break;
      case "signOut":
        // setUser(null);
        setAuthState("LOGGED_OUT");
        break;
      case "signIn_failure":
        // console.log("the user failed to sign in");
        // console.log("the error is", data.payload.data);
        // setUser(null);
        setAuthState("LOGGED_OUT");
        break;
      default:
        // Ignores various hosted ui events.
        // console.log("unhandled event", data.payload.event);
        break;
    }
  });

  // Detects that the user is already logged in
  React.useEffect(() => {
    let isMounted = true;

    ReactGA.pageview('/newaccount');

    Auth.currentAuthenticatedUser()
      // .then((_user) => {
      .then(() => {
        if (isMounted) {
          // we have a user who was logged in previously
          // setUser(_user);
          setAuthState("LOGGED_IN");
        }
      })
      .catch((e) => {
        setAuthState("LOGGED_OUT");
      });

      return () => {
        isMounted = false;
      }
  }, [location]);

  // consistent way to retrieve user token
  const getToken = () => {
    if (appContextState.user) {
      return appContextState.user.signInUserSession.idToken.payload.qualtrics_token;
    }
    return undefined; // "no user";
  };


  return (
    <div>
      {authState === "LOGGED_OUT" && (
        <>
          <h2>{t("invite:heading--logged-out")}</h2>
          <div dangerouslySetInnerHTML={{ __html: t("invite:instructions") }} />
          <OAuthSignInButton id="btn-invite-sign-in" destination={AuthPaths.CreateAccount} />
          <p>
            {t("support:questions-heading")} <span dangerouslySetInnerHTML={{ __html: t("support:email-string-participant") }}></span>
          </p>
        </>
      )}

      {authState === "LOGGED_IN" && (
        <div>
          {invitation &&
            <div>
              <Invitation
                qualtrics_token={getToken()}
                setInvitationState={setInvitationState}
              />
            </div>
          }

          <div>
            {/*
            invitationState can be one of:
              OK
              ERROR: Invalid token.
              ERROR: invitation already redeemed.
              ERROR: invitation does not exist.
              WAITING: invitation check is being done
              NULL
            */}

            { (invitationState === "OK" || invitationState === "ERROR: user already consented.") ? (
              history.replace("/surveys?survey_complete")
            ):( invitationState === "WAITING" ? (
              <LoadingMessage />
            ):(
              <div>
                <h2>{ t("invite:account-error") }</h2>
                {/* <p><strong>Error:</strong> <pre>{invitationState}</pre></p> */}
                <div dangerouslySetInnerHTML={{ __html: t("invite:account-fixes") }}></div>
                <br />
                <h2>{ t("enroll:troubleHeading") }</h2>
                <div dangerouslySetInnerHTML={{ __html: t("enroll:troubleContent") }}></div>
              </div>
            ))}

          </div>
        </div>

      )}
    </div>
  );
};

export default InvitePage;
