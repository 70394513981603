import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { EmailTargets } from '../constants'
import { UserContext } from "../Contexts/UserContext";


export const KeyKitLandingPage = () => {
  const appContextState:any = React.useContext(UserContext);
  const user:any = appContextState.user;
  const loading:boolean = appContextState.loading;

  const { t } = useTranslation();
  const history = useHistory();

  if (loading) {
    return (null);
  }

  if (!user) {
    return (
      <div>
        <h1>{t("keykit:welcome")}</h1>
        <p dangerouslySetInnerHTML={{ __html: t("keykit:instructions") }} />
        <button
          id="keykitlanding--sign-in"
          className="key-btn-signin"
          onClick={() => {
            history.replace("/keykit-signin");
          }}
        >
          {t("general:sign-in")}
        </button>
        <p dangerouslySetInnerHTML={{ __html: t("keykit:help")+EmailTargets.QuestionsAuthed+"." }} />
      </div>
    );
  }

  // signed in, just route to survey
  history.replace("/keykit-signin");

  return (null);
}
