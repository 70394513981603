import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withOAuth } from "aws-amplify-react";
import { Auth } from "aws-amplify";

/**
 * A plain button with aws sign out.
 * https://react.i18next.com/latest/using-with-hooks#using-the-withtranslation-hoc
 * TODO - refactor as a functional component
 **/
class _OAuthSignOutButton extends Component {
  // load the translator hook
  render() {
    //@ts-ignore
    const { t } = this.props;
    return <button className="key-btn-signout" onClick={ () =>
      Auth.signOut().then(() => {
        console.log('signed out')
        window.location.reload(true)
      })
    }>{t("general:sign-out")}</button>;
  }
}
// eslint-disable-next-line
export const OAuthSignOutButton = withTranslation()(withOAuth(_OAuthSignOutButton));