import React, { useState } from "react";

import { ConfirmSignUp, ForgotPassword, SignIn, CreateAccount } from './formStates'


/**
 * parameters to pass to Code
 */
type EmailAuthProps = {
  authState: string,
  setUserStateCB: Function
} ;


/**
 * Display message, manage userState of Email auth workflow
 */
export const EmailAuth = (props: EmailAuthProps) => {
  let [message, setMessage] = useState<string | null>(null);

  const setUserState = (_userState:string) => {
    setMessage(null);
    props.setUserStateCB(_userState)
  }

  return (
    <React.Fragment>
      <form className="form-auth email-auth" onSubmit={(e) => e.preventDefault()}>
        {message && (
          <div className="response-code">
            <pre>
              <code>{message}</code>
            </pre>
          </div>
        )}
        <div className="user-form">
          <SignIn
            userState={props.authState}
            setUserState={setUserState}
            setMessage={setMessage}
          />
          <CreateAccount
            userState={props.authState}
            setUserState={setUserState}
            setMessage={setMessage}
          />
          <ConfirmSignUp
            userState={props.authState}
            setUserState={setUserState}
            setMessage={setMessage}
          />
          <ForgotPassword
            userState={props.authState}
            setUserState={setUserState}
            setMessage={setMessage}
          />
        </div>
      </form>
    </React.Fragment>
  );
};