import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { useTranslation } from "react-i18next";

import { AuthProps } from '../t_AuthProps'
import { AuthPaths } from '../../../../constants/AuthPaths' 

import { ConfirmationCode, Email, Password } from '../../formFields'
import { HelperLink } from '../HelperLink'


/**
 * handle 'forgot-password' or transition to sign-in
 */
export const ForgotPassword = ({ userState, setUserState, setMessage }: AuthProps) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation('auth');

  const handleForgotPassword = async () => {
    try {
      const data = await Auth.forgotPassword(email);
      console.log("custom forgotPassword ok:", data);
      setMessage(t("code-sent-check-email"));
    } catch (error) {
      console.log(error);
      setMessage(error.message);
    }
  };

  const handleForgotPasswordSubmit = async () => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      setUserState(AuthPaths.SignIn);
    } catch (error) {
      console.log('abc')
      console.log(error);
      setMessage(error.message);
    }
  };

  if (userState !== 'forgot-password') {
    return null;
  }

  const _email: string = localStorage.getItem("signUpEmail") || "";
  if (_email !== '' && email === '') {
    setEmail(_email)
    setMessage(t("code-sent-check-email"));
  };

  return (
    <React.Fragment>
      <h5>{t("forgot-password")}</h5>
      <Email setEmailInCaller={setEmail} wide={true} emailValue={email} />
      <ConfirmationCode setCodeInCaller={setCode} />
      <Password setPasswordInCaller={setPassword} wide={true} />
      
      <div className="form-row auth-helper-links auth-bottom-content">
        <HelperLink 
          displayText={t("lost-code-question")}
          buttonText={t("resend-code")}
          buttonId={"resend-code"}
          onClickCB={(e:any) => handleForgotPassword()}
        />
        <HelperLink 
          buttonText={t("back-to-sign-in")}
          buttonId={"back-to-sign-in"}
          onClickCB={(e:any) => setUserState(AuthPaths.SignIn)}
        />
      </div>

      <input
        type="submit"
        id="forgot-password-button"
        className="btn-sign-in"
        onClick={(e) => handleForgotPasswordSubmit()}
        //@ts-ignore
        value={t("reset-password")}
      />
    </React.Fragment>
  );
};