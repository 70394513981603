import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";

import { EmailAuth } from './emailAuth/EmailAuth'
import { ExternalAuth } from './externalAuth/ExternalAuth'

import { SignInBlock } from '../SignIn/SignInBlock'
import { Questions } from '../../Questions'

import { AuthPaths } from '../../constants/AuthPaths'

import "../../assets/scss/componentCss/K2OAuthenticator/K2OAuthenticator.scss";
import 'roboto-fontface'


/**
 * parameters to pass to Code
 */
type K2OAuthenticatorProps = {
  destination: string
} ;


const navLoginOptionConfig: {[index: string]:any} = {
  [AuthPaths.CreateAccount]: {
    showSocialLogin: true,
    showEmailLogin: true
  },

  [AuthPaths.SignIn]: {
    showSocialLogin: true,
    showEmailLogin: true
  },

  [AuthPaths.ForgotPassword]: {
    showSocialLogin: false,
    showEmailLogin: true
  },

  [AuthPaths.ConfirmSignUp]: {
    showSocialLogin: false,
    showEmailLogin: true
  }

};


/**
 * Wrapper for both External and Email Auth
 */
export const K2OAuthenticator = (props: K2OAuthenticatorProps) => {
  const { t, i18n } = useTranslation();
  const [userState, _setUserState] = React.useState(props.destination || 'unknown');

  const setUserState = (_userState:string) => {
    
    _setUserState(_userState)
    window.history.pushState({}, document.title, "/"+_userState)
  }

  useEffect(() => {
    props.destination === undefined ? _setUserState('unknown') : _setUserState(props.destination)
  }, [props]);
  
  return (
    <div className="k2oauthenticator">
      { // Section Header - show if exists
        i18n.exists("auth:section-header-"+userState) 
        ? <h3 className="k2oauthenticator-section-header">{t("auth:section-header-"+userState)}</h3>
        : ''
      }

      { // Section Header Description - show if exists
        i18n.exists("auth:section-subheader-"+userState) 
        ? <div className="k2oauthenticator-section-subheader">{t("auth:section-subheader-"+userState)}</div>
        : ''
      }

      <div className="signin-method-container">
        {navLoginOptionConfig[userState].showSocialLogin && (
          <React.Fragment>
            <div className="signin-method-item social-auth">
              <ExternalAuth authState={userState} setUserStateCB={setUserState} />
            </div>
          
            <div className="signin-method-divider-wrapper">
              <div className="signin-method-divide" style={{ margin: "10px", color: "#777" }}>or</div>
            </div>
          </React.Fragment>
        )}

        {navLoginOptionConfig[userState].showEmailLogin && (
          <div className="signin-method-item email-auth">
            <EmailAuth authState={userState} setUserStateCB={setUserState} />
          </div>
        )}
      </div>

      { // Section Help Header - show if exists
        i18n.exists("auth:section-help-header-"+userState) 
        ? <h3 className="k2oauthenticator-section-help-header">{t("auth:section-help-header-"+userState)}</h3>
        : ''
      }

      { // Section Help Text - show if exists
        i18n.exists("auth:section-help-"+userState) 
        ? <div className="k2oauthenticator-section-help-" dangerouslySetInnerHTML={{ __html: t("auth:section-help-"+userState) }} />
        : ''
      }

      { userState === AuthPaths.SignIn || userState === AuthPaths.ForgotPassword
        ? ''
        : <SignInBlock />
      }
      
      <Questions />
    </div>
  );
}


