import React from 'react';

/**
 * parameters to pass to Code
 */
type HelperLinkProps = {
  displayText?: string,
  buttonText: string,
  buttonId: string,
  onClickCB: Function
} ;


export const HelperLink = (props: HelperLinkProps) => {
  return (
    <div className="signin-helper-link">
      {props.displayText}{" "}
      <button
        type="button"
        className="link-button"
        id={"link-"+props.buttonId}
        onClick={(e:any) => props.onClickCB(e)}
      >
        {props.buttonText}
      </button>
    </div>
  )
}