import React from "react";
import { useMutate } from "restful-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { OAuthSignInButton, OAuthSignOutButton } from './buttons';
import { ContentList } from './ContentList';

import { AuthPaths } from './constants/AuthPaths';
import { ConsentStates } from './constants/ConsentStates';
import { getToken, getPin } from './Authentication/Functions';
import { LoadingMessage } from './LoadingMessage';

import { UserContext } from "./Contexts/UserContext";


const MySurveysWrapper = () => {
  const { t } = useTranslation();

  return (
    <div className="my-surveys-wrapper">
      <Link to={"/surveys"}>
        <button className="key-btn-mysurveys">{t("surveys:link-text")}</button>
      </Link>
      <OAuthSignOutButton />
    </div>
  )
}

/**
 * Redirect users to the correct survey location.
 */
export const Go = () => {
  const appContextState:any = React.useContext(UserContext);
  const user:any = appContextState.user;
  const loading:boolean = appContextState.loading;

  const [destination, setDestination] = React.useState<any>(null);
  const [currentUserConsent, setCurrentUserConsent] = React.useState<ConsentStates>(ConsentStates.Undefined)
  
  const { t } = useTranslation();

  const { mutate: getUserSurveys } = useMutate({
    verb: "POST",
    path: `/user-surveys`,
  });

  /**
   * parameters to survey
   */
  const pin = getPin()
  //const language = getLanguage();
  const surveyUrl = process.env.REACT_APP_SURVEY_URL;

  // //@ts-ignore must be set
  // const DAILY_SURVEY_ID:string = process.env.REACT_APP_DAILY_SURVEY_ID;
  //@ts-ignore
  const CONSENT_SURVEY_ID:string = process.env.REACT_APP_CONSENT_SURVEY_ID;
  // must be set
  if (!CONSENT_SURVEY_ID) {
    console.error("ERROR: Please configure survey ids.")
  }

  /**
   * What we expect back from the /user-surveys call
   */
  interface ISurvey {
      user_id: string;
      survey_id: string;
      finished: number;
      status: string;
      timestamp: Date;
      consented: number;
  }

  /**
   *  An dictionary/hashmap, keyed by survey_id
   */
  type Surveys = { [id: string] : ISurvey; }

  /**
   * render the results from the service to a dictionary
   * returns {} if malformed input
   * returns {} if no surveys
   * returns following if surveys
   * {
   * "SV_08wIq3GLxhlODop":{
   *  "consent_q22":2,
   *  "user_id":"....",
   *  "survey_id":"SV_08wIq3GLxhlODop",
   *  "finished":1,
   *  "status":"normal",
   *  "timestamp":"...."
   *  },
   * "SV....": {...}
   * }
   * @param _surveys /user-surveys output
   */
  const getSurveys = (_surveys: any): Surveys => {
    const emptySurveys: Surveys = {};
    // malformed input
    if (!_surveys || !Array.isArray(_surveys)) {
      return emptySurveys;
    }
    // transform to dict
    const fullSurveys = _surveys.reduce(function(map, obj) {
      map[obj.survey_id] = obj;
      return map;
    }, emptySurveys);
    return fullSurveys;
  };



  /**
   * Does the consent survey exist and did they answer in the affirmative?
   * @param surveys
   */
  const isConsented = (surveys:Surveys): boolean => {
    return surveys[CONSENT_SURVEY_ID]?.consented === ConsentStates.Consented
  }

  /**
   * Does the consent survey exist and did they answer in the negative?
   * @param surveys
   */
  const isDissented = (surveys:Surveys): boolean => {
    return surveys[CONSENT_SURVEY_ID]?.consented === ConsentStates.Dissented
  }

  /**
   * returns destination url depending on survey state, undefined if no user
   *
   * @param surveys
   */
  const getDestination = (surveys: Surveys, user:any): string|undefined => {
    const defaultDestination = undefined;
    if (isConsented(surveys)) {
      // Consented. Send to landing page.
      return "landing"
    }
    if (isDissented(surveys)) {
      // Consent denied. Redirect to content site.
      return "http://www.ohsu.edu/keystudy";
    }
    if (user) {
      return "consent";
    }
    return defaultDestination;
  };

  // fetch the info of the user that may be already logged in
  React.useEffect(() => {
    let isMounted = true;

    const qualtrics_token = getToken(user);
    getUserSurveys({ user_id: qualtrics_token }).then((_surveys) => {
      if (isMounted) {
        const surveys = getSurveys(_surveys);
        const destination = getDestination(surveys, user);

        // If current user has a consent survey, set state to their response
        (typeof surveys[CONSENT_SURVEY_ID] !== 'undefined') 
          ? setCurrentUserConsent(surveys[CONSENT_SURVEY_ID].consented)
          : setCurrentUserConsent(ConsentStates.None)

        setDestination(destination);
      }
    });

    return () => {
      isMounted = false;
    }
  // eslint-disable-next-line
  }, []);
  // above line gives a "Either include it or remove the dependency array"
  // removing it causes a re-render loop?

  /********
   * 
   * Determine what to render here or where to redirect
   *    The next series of ifs/etc handle determining what to render in the <Go /> block. 
   *    Might be enroll text, Auth Btn, a redirect, or even nothing
   * 
   *******/

  /**
   * config error
  */
  if (!surveyUrl) {
    console.error("Please set REACT_APP_SURVEY_URL");
    return (
      <div>
        <h1>{t("general:surveys-heading")}</h1>
        <h2>"Deployment error: Please set REACT_APP_SURVEY_URL in environment"</h2>
      </div>
    );
  }

  /**
   * User not authenticated.
   * This happens before pin/address is verified but after they authenticate.
  */
  if (!user && !loading) {
    return (
      <div className="sign-in-wrapper-row row">
        <div className="block--create-account column">
          <h2>{t("verified:heading")}</h2>

          <div className="signin-verified--content-wrapper">
            <p className="signin-verified--content no-margin-bottom no-margin-top" dangerouslySetInnerHTML={{ __html: t("verified:content") }} />
            <p className="signin-verified--content-list-header no-margin-bottom" dangerouslySetInnerHTML={{ __html: t("verified:content-list-header") }} />
            <ContentList listName={"verified-content"} listItemsContent={t("verified:content-list")} />
          </div>
          <OAuthSignInButton id="go--create-account" destination={AuthPaths.CreateAccount} />
        </div>
      </div>
    );
  }

  /**
   * user not screened
  */
  if (
    (!pin && currentUserConsent === ConsentStates.None) //user not screened
    || (destination === "landing" && (!pin && currentUserConsent === ConsentStates.None)) // no daily - render dashboard && no pin --> enroll
  ) {
    window.location.replace(AuthPaths.Enroll);
    return <LoadingMessage />;
  }

  /**
   * waiting on server
   */
  if (loading || !user || currentUserConsent === ConsentStates.Undefined) {
    return <div className="loading-text">{t("general:loading")}</div>
  }

  if (typeof sessionStorage.targetDestination !== 'undefined' && sessionStorage.targetDestination !== null && currentUserConsent === ConsentStates.Consented){
    window.location.replace(sessionStorage.targetDestination);
    return <LoadingMessage />;
  }

  /**
   * default "main" content
  */
  return <MySurveysWrapper />
};
