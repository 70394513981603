import React from "react";
import BannerImage from '../assets/img/k2o-banner--930.jpg'
import ReactGA from 'react-ga';

import { LandingContentAnon } from './contentBlocks/LandingContentAnon'
import { LandingContentAuthenticated } from './contentBlocks/LandingContentAuthenticated'
import { Partners } from '../Partners'
import { LoadingMessage } from '../LoadingMessage';

import { UserContext } from "../Contexts/UserContext";

import "../assets/scss/componentCss/Landing.scss";
// import { LandingVideo } from "./LandingVideo";

/**
 * in your redirected sign in page
 * when the page is loaded, run the following function
 * TODO - this could be improved somehow?  Is the redirect necessary?
 * Shouldn't Cognito route back to a specific screen?
 */
export const Landing = () => {
  const appContextState:any = React.useContext(UserContext);

  // Fire off GA
  React.useEffect(() => {
    ReactGA.pageview('/landing');
  }, []);

  return (
    <div className="page page--landing">
      <img
        src={BannerImage}
        className="image--banner"
        alt="Key to Oregon: blue box with Mt. Hood at sunset shown inside of a keyhole."
      />

      <div id="landing--layout--wrapper" className="column">
        { appContextState.loading 
          ? <LoadingMessage />
          : appContextState.user === null
            ? <LandingContentAnon />
            : <LandingContentAuthenticated />
        }
        {/* <LandingVideo vidaddress="4wM3EykCJmk" /> */}
        <Partners />
      </div>             
    </div>
  );
};
