import React from "react";
import { useTranslation } from "react-i18next";

import { SocialButton } from './SocialButton'


/**
 * parameters to pass to Code
 */
type ExternalAuthProps = {
  authState?: string,
  setUserStateCB?: Function
} ;


/**
 * Display message, manage userState of Email auth workflow
 */
export const ExternalAuth = (props: ExternalAuthProps) => {
  const { t } = useTranslation("auth");

  return (
    <div className="form-auth social-auth" onSubmit={(e) => e.preventDefault()}>
      <h5>
        {
          props.authState === 'create-account'  
          ? t("create-account-social-header")
          : t("sign-in-social-header")
        }
      </h5>
      <SocialButton provider="Google" />
      <SocialButton provider="Facebook" />
      <div className="form-row ext-auth-notice auth-bottom-content">
        {t("social-wont-post-notice")}
      </div> 
    </div>
  );
};